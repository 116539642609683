<template lang="pug">
  section.qa
    .container
      h2.qa-title У нас часто спрашивают:

      .qa__grid
        .accordion
          .accordion__item.accordion__item_active
            button.accordion__item-button(type="button") Что еще может ваш сервис?
            .accordion__item-content
              .accordion__item-content-inner.active
                p Быстро заменять оборудование на аналоги других брендов.
                p Определять тепловую нагрузку на вентиляцию.
                p Подбирать необходимый слой утеплителя для теплого пола.
                p Проверять разные режимы работы теплого пола, предлагать варианты экономии на материалах без потери качества.

          .accordion__item
            button.accordion__item-button(type="button") Насколько точны результаты расчета и можно ли им верить?
            .accordion__item-content
              .accordion__item-content-inner
                p Верить нам на слово мы и не предлагаем — проверьте.
                p Мы опубликовали все методики, на основании которых ведем расчеты, и нас легко проверить. Все алгоритмы расчетов основаны на методиках из классических учебников и справочников для проектировщиков.
                p Результаты расчетов проверяем на соответствие требованиям СП 50.13330.2012 Тепловая защита зданий и DIN EN 1264 Система обогрева поверхностей.
                p После проверки, сервис заслужил доверие со стороны известных брендов инженерного оборудования.

          .accordion__item
            button.accordion__item-button(type="button") Получу ли я схему отопления в конце расчета?
            .accordion__item-content
              .accordion__item-content-inner
                p В нашем сервисе нет возможности нарисовать схему отопления.
                p Результаты расчета представлены в виде таблиц с данными.
                p Вы можете использовать типовые схемы отопления производителей отопительного оборудования или заказать разработку принципиальной схемы отопления у нас.

        .accordion
          .accordion__item
            button.accordion__item-button(type="button") Смогу ли я самостоятельно сделать расчет без ошибок?
            .accordion__item-content
              .accordion__item-content-inner
                p Да, вы сможете самостоятельно сделать расчет отопления, даже если делаете это впервые.
                p У нас удобный интерфейс с подсказками и автоматической проверкой введенных данных на ошибки.
                p Рекомендуем посмотреть обучающие ролики, чтобы понять принцип работы в сервисе.
                p Если что-то непонятно - задавайте нам вопросы в чате - мы, обязательно ответим и поможем.

          .accordion__item
            button.accordion__item-button(type="button") Сможете ли вы потом проверить мой расчет, чтобы исключить ошибки?
            .accordion__item-content
              .accordion__item-content-inner
                p Напишите нам в чат запрос на проверку вашего расчета.
                p Мы вместе с вами посмотрим на ваш расчет и в случае необходимости сразу поправим.

          .accordion__item
            button.accordion__item-button(type="button") Могу я заказать у вас расчет и монтаж отопления?
            .accordion__item-content
              .accordion__item-content-inner
                p.
                  Нашим сервисом пользуются профессиональные сертифицированные монтажники из разных городов.
                  Мы можем порекомендовать вам квалифицированного монтажника из вашего региона, который
                  выполнит и расчет, и монтаж отопления вашего дома.
                p Напишите нам в онлайн-чат – и мы подберем для вас проверенного специалиста.
</template>

<script>
import accordionMixin from 'mixins/accordion'

export default {
  name: 'Qa',
  mixins: [accordionMixin]
}
</script>
