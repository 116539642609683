<template lang="pug">
  section.advantages
    .container
      .advantages__header
        h2.advantages__title Строите сами?
        .advantages__subtitle Принимайте правильные решения,
          br
          | сделав расчеты у нас!

      .advantages__grid
        .advantages__item
          .advantages__item-icon
            include ../assets/images/icons/warm-floor.svg
          .advantages__item-title Проверьте быстро
          .advantages__item-desc В каких помещениях хватит теплого пола и можно обойтись без радиаторов.

        .advantages__item
          .advantages__item-icon
            include ../assets/images/icons/efficiency.svg
          .advantages__item-title Сэкономьте на материалах до 30%
          .advantages__item-desc За счет оптимальной конфигурации системы, не теряя в качестве.

        .advantages__item
          .advantages__item-icon
            include ../assets/images/icons/shield.svg
          .advantages__item-title Подберите надежное
          .advantages__item-desc В&nbsp;наших справочниках только то&nbsp;оборудование и&nbsp;материалы, в&nbsp;которых уверены сами, и&nbsp;которое имеет протоколы испытаний.

        .advantages__item
          .advantages__item-icon
            include ../assets/images/icons/study.svg
          .advantages__item-title Получайте знания
          .advantages__item-desc С помощью обучающих материалов внутри сервиса. Консультируйтесь по расчетам через онлайн-чат.

      .advantages__button
        .advantages-arrow
          .advantages-arrow__icon
          .advantages-arrow__title У вас получится!
          .advantages-arrow__desc Даже, если вы делаете это впервые!

        a.button(@click="goSignUp") Регистрация и расчет
</template>

<script>
import navigation from 'mixins/navigation'
export default {
  name: 'Advantages',
  mixins: [navigation]
}
</script>
