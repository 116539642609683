<template lang="pug">
  div
    Banner
    Simple
    Advantages
    Advantages2
    Result
    Gost
    Completed
    Qa
</template>
<script>
import Banner from '../components/Banner'
import Simple from '../components/Simple'
import Advantages from '../components/Advantages'
import Advantages2 from '../components/Advantages2'
import Result from '../components/Result'
import Gost from '../components/Gost'
import Completed from '../components/Completed'
import Qa from '../components/Qa'
import navigationMixin from '../mixins/navigation'

export default {
  name: 'Landing',
  components: {
    Banner,
    Simple,
    Advantages,
    Advantages2,
    Result,
    Gost,
    Completed,
    Qa
  },
  mixins: [navigationMixin],
  metaInfo: {
    title: 'Онлайн калькулятор отопления загородного дома',
    meta: [
      {
        name: 'description',
        content: 'Профессиональный калькулятор расчета отопления поможет вам определить теплопотери дома, подобрать водяной теплый пол и радиаторы. Обучение и консультации для самостройщиков.'
      }
    ]
  }
}
</script>
