<template lang="pug">
  section.banner
    .container
      .banner__header
        .banner__subtitle С обучением и консультациями
          br
          span.mobile-only  по расчетам
          button.button.button__mobile-only(@click="goSignUp" style="width: 327px") Попробовать бесплатно
        h1.banner__title
          span.banner__mobile-small Профессиональный
          |  калькулятор
          br
          | отопления
          span.banner__mobile-hidden  загородного
          |  дома

      .banner__advantages
        .banner__advantages-item(@click="goHeatloss")
          .banner__advantages-item-img
            include ../assets/images/banner-1.svg
          .banner__advantages-item-title Расчет теплопотерь <svg width="24" height="24" viewBox="0 -5 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 16L14 12L10 8" stroke="#F15C53" stroke-width="1.5" stroke-linecap="round"/></svg>
          .banner__advantages-item-desc Считайте упрощенно или подробно. Узнайте необходимую мощность котла.

        .banner__advantages-item(@click="goFloor")
          .banner__advantages-item-img
            include ../assets/images/banner-2.svg
          .banner__advantages-item-title Расчет теплого пола<svg width="24" height="24" viewBox="0 -5 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 16L14 12L10 8" stroke="#F15C53" stroke-width="1.5" stroke-linecap="round"/></svg>
          .banner__advantages-item-desc Теплоотдача, количество и длина веток, диаметр и шаг укладки трубы, настройка.

        .banner__advantages-item(@click="goRadiator")
          .banner__advantages-item-img
            include ../assets/images/banner-3.svg
          .banner__advantages-item-title Расчет радиаторов <svg width="24" height="24" viewBox="0 -5 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 16L14 12L10 8" stroke="#F15C53" stroke-width="1.5" stroke-linecap="round"/></svg>
          .banner__advantages-item-desc Секционные и панельные, с вентилем и без. Реальная и паспортная теплоотдача.

</template>

<script>
import navigation from 'mixins/navigation'

export default {
  name: 'Banner',
  mixins: [navigation]
}
</script>
